<template>
  <div class="pageContol listWrap templateList shopDecorate">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">店铺管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">店铺装修</a>
        </span>
        <span>
          <el-button class="bgc-bv" @click="doSave" size="small"
            >发布</el-button
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexjc">
              <div class="phonebox flexdc">
                <div class="phone_top">
                  <div>
                    <span style="cursor: pointer" @click="editTitle('1')"
                      >{{ title1 }} <i class="el-icon-edit"></i
                    ></span>
                  </div>
                  <div class="flexcb setBox">
                    <i class="el-icon-more"></i>
                    <el-divider direction="vertical"></el-divider>
                    <i class="el-icon-remove-outline"></i>
                  </div>
                </div>
                <div
                  style="overflow-y: scroll"
                  class="phone_content"
                  ref="appbox"
                >
                  <div
                    class="phone_banner df"
                    @mouseenter="modalShow('1', '10')"
                    @mouseleave="modalNone('1', '20')"
                    id="banner"
                  >
                    <el-carousel
                      style="width: 100%"
                      height="150px"
                      loop
                      :interval="2000"
                      v-if="bannerData.length"
                    >
                      <el-carousel-item
                        v-for="(item, index) in bannerData.filter(
                          (el) => !el.hidden
                        )"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.icoPath"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                    <div v-else style="" class="flexdcc flex1">
                      <span>轮播图位置</span>
                    </div>
                    <div class="modal" v-if="show1">
                      <div>
                        <img
                          src="@/assets/app_home/app_edit.png"
                          @click="editBanner"
                          alt=""
                        />
                        <img
                          @click="isBlock('1', '10')"
                          v-if="block1"
                          src="@/assets/app_home/app_block.png"
                          alt=""
                        />
                        <img
                          @click="isBlock('1', '20')"
                          v-else
                          src="@/assets/app_home/app_none.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <!--  -->
                  <div
                    class="phone_application df flexwp"
                    @mouseenter="modalShow('2', '10')"
                    @mouseleave="modalNone('2', '20')"
                    id="application"
                    style="flex-shrink: 0"
                  >
                    <template v-if="applicationData.length">
                      <div
                        class="application_item flexdc flexca"
                        v-for="(item, index) in applicationData"
                        :key="index"
                      >
                        <img :src="item.icoPath" alt="" />
                        <span
                          style="
                            width: 100%;
                            word-break: break-word;
                            text-align: center;
                          "
                          >{{ item.name }}</span
                        >
                      </div>
                    </template>
                    <div v-else style="width: 100%">
                      <span>应用位置</span>
                    </div>
                    <div class="modal" v-if="show2">
                      <div>
                        <img
                          src="@/assets/app_home/app_edit.png"
                          @click="editApplication"
                          alt=""
                        />
                        <img
                          @click="isBlock('2', '10')"
                          v-if="block2"
                          src="@/assets/app_home/app_block.png"
                          alt=""
                        />
                        <img
                          @click="isBlock('2', '20')"
                          v-else
                          src="@/assets/app_home/app_none.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <!--  -->
                  <div
                    id="pushBanner"
                    class="pushBanner"
                    @mouseenter="modalShow('3', '10')"
                    @mouseleave="modalNone('3', '20')"
                  >
                    <el-carousel
                      style="width: 100%"
                      height="120px"
                      loop
                      :interval="2000"
                      v-if="homeAdData.length"
                    >
                      <el-carousel-item
                        v-for="(item, index) in homeAdData.filter(
                          (el) => el.state
                        )"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.imgUrl"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                    <div v-else style="" class="flexdcc flex1">
                      <span>广告图位置</span>
                    </div>
                    <div class="modal" v-if="show3">
                      <div>
                        <img
                          src="@/assets/app_home/app_edit.png"
                          @click="editAd"
                          alt=""
                        />
                        <img
                          @click="isBlock('3', '10')"
                          v-if="block3"
                          src="@/assets/app_home/app_block.png"
                          alt=""
                        />
                        <img
                          @click="isBlock('3', '20')"
                          v-else
                          src="@/assets/app_home/app_none.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="Selected"
                    id="Selected"
                    @mouseenter="modalShow('4', '10')"
                    @mouseleave="modalNone('4', '20')"
                  >
                    <div class="top">
                      <span @click="editTitle('2')"
                        >{{ title2 }} <i class="el-icon-edit"></i
                      ></span>
                    </div>
                    <template v-if="selectedData.length">
                      <div
                        class="course_list df"
                        v-for="(item, index) in selectedData"
                        :key="index"
                      >
                        <img :src="item.icoPath" alt="" />
                        <span>{{ item.courseName }}</span>
                      </div>
                    </template>
                    <div v-else>无数据</div>
                    <div class="modal" v-if="show4">
                      <div>
                        <img
                          @click="isBlock('4', '10')"
                          v-if="block4"
                          src="@/assets/app_home/app_block.png"
                          alt=""
                        />
                        <img
                          @click="isBlock('4', '20')"
                          v-else
                          src="@/assets/app_home/app_none.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <!-- 职场充电 -->
                  <div
                    class="workPlace"
                    @mouseenter="modalShow('5', '10')"
                    @mouseleave="modalNone('5', '20')"
                  >
                    <div class="top">
                      <span @click="editTitle('3')"
                        >{{ title3 }} <i class="el-icon-edit"></i
                      ></span>
                    </div>
                    <el-row
                      style="position: relative; z-index: 100"
                      :gutter="10"
                    >
                      <el-col
                        style="margin-bottom: 10px"
                        :span="8"
                        v-for="(el, ind) in workTypeList"
                        :key="ind"
                      >
                        <el-button
                          round
                          style="width: 100px"
                          size="mini"
                          :class="
                            courseTypeId == el.courseTypeId ? 'bgc-bv' : ''
                          "
                          @click="getworkPlace(el.courseTypeId)"
                          >{{ el.name }}</el-button
                        >
                      </el-col>
                    </el-row>
                    <template v-if="infosData.length">
                      <!-- workTypeList -->

                      <div
                        class="course_list df"
                        v-for="(item, index) in infosData"
                        :key="index"
                      >
                        <img :src="item.icoPath" alt="" />
                        <span>{{ item.title }}</span>
                      </div>
                    </template>
                    <div v-else>无数据</div>
                    <div class="modal" v-if="show5">
                      <div>
                        <img
                          @click="isBlock('5', '10')"
                          v-if="block5"
                          src="@/assets/app_home/app_block.png"
                          alt=""
                        />
                        <img
                          @click="isBlock('5', '20')"
                          v-else
                          src="@/assets/app_home/app_none.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="phone_bot flexca flexac">
                  <span style="font-weight: 700">首页</span>
                  <span>学习</span>
                  <span>题库</span>
                  <span>我的</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="轮播图"
      :visible.sync="bannerDialog"
      width="80%"
      :before-close="bannerEditCencal"
    >
      <div class="flexcb">
        <p>温馨提示：为了达到页面效果，建议上传图片的大小为1000*450</p>
        <el-button size="small" @click="addBanner">添加图片</el-button>
      </div>
      <el-table :data="bannerData" height="500">
        <el-table-column label="图片" align="center">
          <div class="flexjc" slot-scope="scope">
            <el-upload
              :before-upload="$beforeAvatarUpload"
              :http-request="
                (res) => {
                  handleAvatarBanner(res, scope.$index);
                }
              "
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="scope.row.icoPath || require('@/assets/develop.png')"
                fit="contain"
                class="imgCenter"
              ></el-image>
            </el-upload>
          </div>
        </el-table-column>
        <el-table-column label="链接" width="400px" align="center">
          <div class="df" slot-scope="scope">
            <div style="width: 35%; margin-right: 3px">
              <el-select
                v-model="scope.row.linkStrategy"
                placeholder="请选择"
                size="mini"
                @change="
                  (val) => {
                    linkChange(val, scope.$index);
                  }
                "
              >
                <el-option
                  v-for="item in jumpType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="flex1">
              <el-select
                v-if="scope.row.linkStrategy == 1"
                v-model="scope.row.linkContent"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="item in scope.row.jumpContentList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-cascader
                v-else-if="scope.row.linkStrategy == 0"
                :options="scope.row.jumpContentList"
                v-model="scope.row.linkContent"
                :props="props"
                placeholder="请选择"
                size="mini"
              ></el-cascader>
              <el-input
                v-else
                size="mini"
                placeholder="请输入内容"
                v-model="scope.row.linkContent"
              >
              </el-input>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="排序" align="center">
          <template slot-scope="scope">
            <el-input
              size="mini"
              placeholder="请输入排序"
              v-model="scope.row.sortNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.hidden"
              :active-value="false"
              :inactive-value="true"
            >
            </el-switch>
            <span>{{ !scope.row.hidden ? "显示" : "隐藏" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <!--  -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="delBannerItem(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bannerEditCencal">取 消</el-button>
        <el-button type="primary" @click="bannerEditOk">确 定</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog
      title="广告位"
      :visible.sync="homeAdDialog"
      width="80%"
      :before-close="adEditCencal"
    >
      <div class="flexcb">
        <p>温馨提示：为了达到页面效果，建议上传图片的大小为1000*450</p>
        <el-button size="small" @click="addAd">添加图片</el-button>
      </div>
      <el-table :data="homeAdData" height="500">
        <el-table-column label="图片" align="center">
          <div class="flexjc" slot-scope="scope">
            <el-upload
              :before-upload="$beforeAvatarUpload"
              :http-request="
                (res) => {
                  handleAvatarAd(res, scope.$index);
                }
              "
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="scope.row.imgUrl || require('@/assets/develop.png')"
                fit="contain"
                class="imgCenter"
              ></el-image>
            </el-upload>
          </div>
        </el-table-column>
        <el-table-column label="链接" width="400px" align="center">
          <div class="df" slot-scope="scope">
            <div style="width: 35%; margin-right: 3px">
              <el-select
                v-model="scope.row.adType"
                placeholder="请选择"
                size="mini"
                @change="
                  (val) => {
                    adLinkChange(val, scope.$index);
                  }
                "
              >
                <el-option
                  v-for="item in jumpType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="flex1">
              <el-select
                v-if="scope.row.adType == 1"
                v-model="scope.row.content"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="item in scope.row.jumpContentList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-cascader
                v-else-if="scope.row.adType == 0"
                :options="scope.row.jumpContentList"
                v-model="scope.row.content"
                :props="props"
                placeholder="请选择"
                size="mini"
              ></el-cascader>
              <el-input
                v-else
                size="mini"
                placeholder="请输入内容"
                v-model="scope.row.content"
              >
              </el-input>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="排序" align="center">
          <template slot-scope="scope">
            <el-input
              size="mini"
              placeholder="请输入内容"
              v-model="scope.row.order"
              oninput="value=value.replace(/[^\d]/g,'')"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
            <span>{{ scope.row.state ? "显示" : "隐藏" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <!--  -->
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="delAdItem(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adEditCencal">取 消</el-button>
        <el-button type="primary" @click="adEditOk">确 定</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog
      title="编辑标题"
      :visible.sync="titleShowDialog"
      width="35%"
      center
    >
      <div class="flexjc flexac">
        <span style="min-width: 20%; text-align: center">标题名称：</span>
        <el-input v-model="commenTitle"></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="titleEditCancel">取 消</el-button>
        <el-button type="primary" @click="titleEditOk">确 定</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog
      title="高频应用"
      :visible.sync="applicationDialog"
      width="60%"
      center
      :before-close="applicationEditCancel"
    >
      <div class="df" style="justify-content: flex-end">
        <el-button size="small" @click="addApplication">添加应用</el-button>
      </div>
      <el-table :data="applicationData" height="500">
        <el-table-column label="图片" align="center">
          <div class="flexjc" slot-scope="scope">
            <el-upload
              :before-upload="$beforeAvatarUpload"
              :http-request="
                (res) => {
                  handleApplicationImg(res, scope.$index);
                }
              "
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="scope.row.icoPath || require('@/assets/develop.png')"
                fit="contain"
                class="imgCenter"
              ></el-image>
            </el-upload>
          </div>
        </el-table-column>
        <el-table-column label="应用名称" align="center">
          <div class="df" slot-scope="scope">
            <el-input
              size="mini"
              placeholder="请输入内容"
              v-model="scope.row.name"
            >
            </el-input>
          </div>
        </el-table-column>
        <el-table-column label="选择应用" align="center">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.shortcutCode"
              filterable
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in applicationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="排序" align="center">
          <template slot-scope="scope">
            <el-input
              size="mini"
              placeholder="请输入内容"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.sortNum"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="学员/机构" align="center">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.type"
              filterable
              placeholder="请选择"
              size="mini"
              ><el-option
                v-for="item in applicationType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <!--  -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="delApplicationItem(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="applicationEditCancel">取 消</el-button>
        <el-button type="primary" @click="applicationEditOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import courseTableMore from "./popup/CourseTableMore";
import Sortable from "sortablejs";
export default {
  name: "miniappDecorate",
  components: {},
  data() {
    return {
      // 换标题
      titleShowDialog: false,
      commenTitle: "",
      titleKey: "",
      title1: "安知学堂",
      title2: "精选推荐",
      title3: "职场充电",
      // 模块显隐
      block1: true, // 轮播图
      block2: true, // 应用
      block3: true, // 广告位
      block4: true, // 精选推荐
      block5: true, // 职场充电
      // 划过蒙层
      show1: false, // 轮播图
      show2: false, // 应用
      show3: false, // 广告位
      show4: false, // 精选推荐
      show5: false, // 职场充电
      banner: 0,
      application: 1,
      pushBanner: 2,
      Selected: 3,
      // 轮播图
      bannerDialog: false,
      bannerData: [],
      bannerDataCopy: "",
      jumpType: [
        {
          label: "课程分类",
          value: 0,
        },
        {
          label: "应用",
          value: 1,
        },
        {
          label: "自定义链接",
          value: 2,
        },
      ],
      // 应用
      applicationDialog: false,
      applicationList: [],
      applicationData: [],
      applicationDataCopy: "",
      //
      // 广告
      homeAdDialog: false,
      homeAdData: [],
      homeAdDataCopy: "",
      selectedData: [],
      // 职场充电
      infosData: [],
      workTypeList: [],
      //
      stu: "edit",
      //
      courseTypeList: [],
      applicationType: [
        {
          label: "机构",
          value: "0",
        },
        {
          label: "学员",
          value: "1",
        },
      ],
      props: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
      courseTypeId: "0",
    };
  },
  created() {
    this.getcourseTypeArrData();
    this.getTypeTree();
    this.doQuery();
    this.getApplicationByDic();
  },
  mounted() {},
  computed: {},
  methods: {
    //
    init() {
      this.bannerData.forEach((el) => {
        el.jumpContentList =
          el.linkStrategy == 0 ? this.courseTypeList : this.applicationList;
      });
      this.bannerDataCopy = JSON.stringify(this.bannerData);
      this.homeAdData.forEach((el) => {
        el.jumpContentList =
          el.adType == 0 ? this.courseTypeList : this.applicationList;
      });
      this.homeAdDataCopy = JSON.stringify(this.homeAdData);
      this.applicationDataCopy = JSON.stringify(this.applicationData);
    },
    // 查询
    doQuery() {
      this.$post("/biz/home/v1/query", {}, 3000, true, 2).then((res) => {
        if (res.status == "0") {
          let data = res.data || {};
          this.bannerData =
            data.carousels.sort((a, b) => {
              return a.sortNum - b.sortNum;
            }) || [];
            
          data.shortcuts.forEach((el) => {
            el.shortcutCode += "";
          });
         
          this.applicationData =
            data.shortcuts.sort((a, b) => {
              return a.sortNum - b.sortNum;
            }) || [];
          this.selectedData = data.recommends || []; // 推荐集合
          this.homeAdData = data.homeAd || []; // 广告
          // this.infosData = data.infos || []; // 职场充电
          this.title1 = data.title || this.title1; //
          data.items.forEach((el) => {
            this["block" + el.module] = !el.hidden;
            if (el.module == 4) {
              this.title2 = el.name;
            }
            if (el.module == 5) {
              this.title3 = el.name;
            }
          });
          this.init();
        }
      });
    },
    // 编辑标题 方法
    editTitle(key) {
      this.titleKey = key;
      switch (key) {
        case "1":
          this.commenTitle = this.title1;
          break;
        case "2":
          this.commenTitle = this.title2;
          break;
        case "3":
          this.commenTitle = this.title3;
          break;
        default:
          break;
      }
      this.titleShowDialog = true;
    },
    titleEditCancel() {
      this["title" + this.titleKey] = this["title" + this.titleKey];
      this.commenTitle = "";
      this.titleShowDialog = false;
    },
    titleEditOk() {
      this["title" + this.titleKey] = this.commenTitle;
      this.titleShowDialog = false;
      this.titleKey = "";
    },
    // 轮播图相关方法
    addBanner() {
      let item = {
        icoPath: "",
        imgkey: "",
        linkStrategy: 0,
        linkContent: "",
        sortNum: "",
        hidden: false,
        jumpContentList: this.courseTypeList,
      };
      this.bannerData.push(item);
    },
    // 编辑轮播图
    editBanner() {
      this.bannerDialog = true;
    },
    // 链接点击
    linkChange(val, ind) {
      this.bannerData[ind].linkContent = ""; // 链接内容改变的时候 链接内容置空
      switch (val) {
        case 1:
          this.bannerData[ind].jumpContentList = this.applicationList;
          break;
        case 0:
          this.bannerData[ind].jumpContentList = this.courseTypeList;
          break;
        default:
          break;
      }
    },
    // 删除某一条轮播图
    delBannerItem(index) {
      this.bannerData.splice(index, 1);
    },
    // banner ok
    bannerEditOk() {
      let passStu = true;
      let tip = "";
      for (let i = 0; i < this.bannerData.length; i++) {
        const el = this.bannerData[i];
        if (el.icoPath == "") {
          tip = "第【" + (i + 1) + "】条未上传图片，请上传图片";
          passStu = false;
          break;
        }
        if (el.linkContent == "") {
          tip = "第【" + (i + 1) + "】条链接内容为空，请填写链接内容";
          passStu = false;
          break;
        }
        if (el.sortNum + "" == "") {
          tip = "第【" + (i + 1) + "】条排序为空，请填写排序";
          passStu = false;
          break;
        }
      }
      if (!passStu) {
        this.$message({
          type: "warning",
          message: tip,
        });
      } else {
        this.bannerDialog = false;
        this.bannerDataCopy = JSON.stringify(this.bannerData);
        this.bannerData = this.bannerData.sort((a, b) => {
          return a.sortNum - b.sortNum;
        });
      }
    },
    bannerEditCencal() {
      this.bannerData = JSON.parse(this.bannerDataCopy);
      this.bannerDialog = false;
    },
    // 轮播图上传图片
    handleAvatarBanner(res, ind) {
      let extension = res.file.name.substring(
        res.file.name.lastIndexOf(".") + 1
      );
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", res.file);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.bannerData[ind].icoPath = result.data.fileURL;
          this.bannerData[ind].imgkey = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 广告相关方法
    addAd() {
      let item = {
        imgUrl: "",
        adType: "",
        content: "",
        order: "",
        state: true,
        jumpContentList: this.courseTypeList,
      };
      this.homeAdData.push(item);
    },
    // 编辑广告
    editAd() {
      this.homeAdDialog = true;
    },
    // 链接点击
    adLinkChange(val, ind) {
      this.homeAdData[ind].content = ""; // 链接内容改变的时候 链接内容置空
      switch (val) {
        case 1:
          this.homeAdData[ind].jumpContentList = this.applicationList;
          break;
        case 0:
          this.homeAdData[ind].jumpContentList = this.courseTypeList;
          break;
        default:
          break;
      }
    },
    // 删除某一条广告
    delAdItem(index) {
      this.homeAdData.splice(index, 1);
    },
    // ad ok
    adEditOk() {
      let passStu = true;
      let tip = "";
      for (let i = 0; i < this.homeAdData.length; i++) {
        const el = this.homeAdData[i];
        if (el.imgUrl == "") {
          tip = "第【" + (i + 1) + "】条未上传图片，请上传图片";
          passStu = false;
          break;
        }
        if (el.content == "") {
          tip = "第【" + (i + 1) + "】条链接内容为空，请填写链接内容";
          passStu = false;
          break;
        }
        if (el.order + "" == "") {
          tip = "第【" + (i + 1) + "】条排序为空，请填写排序";
          passStu = false;
          break;
        }
      }
      if (!passStu) {
        this.$message({
          type: "warning",
          message: tip,
        });
      } else {
        this.homeAdDialog = false;
        this.homeAdDataCopy = JSON.stringify(this.homeAdData);
        this.homeAdData = this.homeAdData.sort((a, b) => {
          return a.sortNum - b.sortNum;
        });
      }
    },
    adEditCencal() {
      this.homeAdData = JSON.parse(this.homeAdDataCopy);
      this.homeAdDialog = false;
    },
    //广告上传图片
    handleAvatarAd(res, ind) {
      let extension = res.file.name.substring(
        res.file.name.lastIndexOf(".") + 1
      );
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", res.file);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.homeAdData[ind].imgUrl = result.data.fileURL;
          this.homeAdData[ind].imgkey = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 应用
    addApplication() {
      let item = {
        icoPath: "",
        imgkey: "",
        name: "",
        shortcutCode: "",
        sortNum: "",
        type: ""
      };
      this.applicationData.push(item);
    },
    editApplication() {
      this.applicationDialog = true;
    },
    handleApplicationImg(res, ind) {
      let extension = res.file.name.substring(
        res.file.name.lastIndexOf(".") + 1
      );
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", res.file);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.applicationData[ind].icoPath = result.data.fileURL;
          this.applicationData[ind].imgkey = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    delApplicationItem(ind) {
      this.applicationData.splice(ind, 1);
    },
    applicationEditCancel() {
      this.applicationDialog = false;
      this.applicationData = JSON.parse(this.applicationDataCopy);
    },
    applicationEditOk() {
      let passStu = true;
      let tip = "";
      for (let i = 0; i < this.applicationData.length; i++) {
        const el = this.applicationData[i];
        if (el.icoPath == "") {
          tip = "第【" + (i + 1) + "】条未上传图片，请上传图片";
          passStu = false;
          break;
        }
        if (el.name == "") {
          tip = "第【" + (i + 1) + "】条应用名称为空，请填写应用名称";
          passStu = false;
          break;
        }
        if (el.shortcutCode == "") {
          tip = "第【" + (i + 1) + "】条应用为空，请选择应用";
          passStu = false;
          break;
        }
        if (el.sortNum + "" == "") {
          tip = "第【" + (i + 1) + "】条排序为空，请填写排序";
          passStu = false;
          break;
        }
        if (el.type + "" == "") {
          tip = "第【" + (i + 1) + "】条排序为空，请填写排序";
          passStu = false;
          break;
        }
      }
      if (!passStu) {
        this.$message({
          type: "warning",
          message: tip,
        });
      } else {
        this.applicationDialog = false;
        this.applicationDataCopy = JSON.stringify(this.applicationData);
        this.applicationData = this.applicationData.sort((a, b) => {
          return a.sortNum - b.sortNum;
        });
      }
    },
    // 职场充电 - 相关
    getTypeTree() {
      this.$post(
        "/miniapp/coursetype/selectTree",
        { hiddenState: "10", parentId: -1 },
        3000,
        true,
        2
      ).then((res) => {
        this.workTypeList = res.data[0].children;
        this.courseTypeId = res.data[0].children[0].courseTypeId;
        this.getworkPlace(this.courseTypeId);
      });
    },
    // 职场列表
    getworkPlace(courseTypeId) {
      this.courseTypeId = courseTypeId;
      this.$post(
        "/biz/home/v1/queryHomeInfo",
        {
          courseTypeId,
          pageNum: 1,
          pageSize: 3,
        },
        3000,
        true,
        2
      ).then((res) => {
        this.infosData = res.data;
      });
    },
    //
    doSave() {
      let items = [
        {
          hidden: !this.block1,
          module: 1,
        },
        {
          hidden: !this.block2,
          module: 2,
        },
        {
          hidden: !this.block3,
          module: 3,
        },
        {
          hidden: !this.block4,
          module: 4,
          name: this.title2,
        },
        {
          hidden: !this.block5,
          module: 5,
          name: this.title3,
        },
      ];
      let carousels = [];
      this.bannerData.forEach((el) => {
        carousels.push({
          hidden: el.hidden,
          icoPath: el.icoPath,
          linkContent: el.linkContent,
          linkStrategy: el.linkStrategy, // 链接
          sortNum: el.sortNum,
          type: el.type
        });
      });
      let shortcuts = [];
      this.applicationData.forEach((el) => {
        shortcuts.push({
          icoPath: el.icoPath,
          name: el.name,
          shortcutCode: el.shortcutCode,
          sortNum: el.sortNum,
          type: el.type
        });
      });
      let homeAd = [];
      this.homeAdData.forEach((el) => {
        homeAd.push({
          adType: el.adType,
          content: el.content,
          imgUrl: el.imgUrl,
          order: el.order,
          state: el.state,
        });
      });
      let params = {
        title: this.title1,
        items,
        shortcuts,
        carousels,
        homeAd,
      };
      this.$post("/biz/home/v1/modify", params, 3000, true, 2).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.doQuery();
        }
      });
    },
    // 划过显示
    modalShow(key, stu) {
      this["show" + key] = true;
    },
    // 移除隐藏
    modalNone(key, stu) {
      this["show" + key] = false;
    },
    // 是否显示
    isBlock(key, stu) {
      switch (key) {
        case "1":
          if (stu == "10") {
            this.block1 = false;
          } else {
            this.block1 = true;
          }
          break;
        case "2":
          if (stu == "10") {
            this.block2 = false;
          } else {
            this.block2 = true;
          }
          break;
        case "3":
          if (stu == "10") {
            this.block3 = false;
          } else {
            this.block3 = true;
          }
          break;
        case "4":
          if (stu == "10") {
            this.block4 = false;
          } else {
            this.block4 = true;
          }
          break;
        case "5":
          if (stu == "10") {
            this.block5 = false;
          } else {
            this.block5 = true;
          }
          break;

        default:
          break;
      }
    },
    // 应用字典
    getApplicationByDic() {
      let arr = this.$setDictionary("HOME_SHORTCUT", "list");
      this.applicationList = [];
      for (const key in arr) {
        this.applicationList.push({
          label: arr[key],
          value: key,
        });
      }
    },
    //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "/miniapp/coursetype/selectTree",
        { hiddenState: "10", parentId: 0 },
        3000,
        true,
        2
      ).then((ret) => {
        this.courseTypeList = this.delchild(ret.data);
      });
    },
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
  },
};
</script>
<style lang="less">
.shopDecorate {
  .img-el-upload {
    width: 80px;
    height: 50px;
    .el-upload {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .el-switch__core {
    width: 45px !important;
  }
}
</style>
<style lang="less" scope>
.shopDecorate {
  .phone_top {
    padding: 20px 13px 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .setBox {
      border: 1px solid #dcdfe6;
      padding: 5px 8px;
      border-radius: 20px;
    }
  }
  .phone_bot {
    border-top: #000 solid 1px;
    padding: 10px 13px 20px;
  }

  .phonebox {
    .top {
      position: relative;
      z-index: 100;
      cursor: pointer;
    }
    width: 375px;
    min-height: 500px;
    height: 750px;
    border: 1px solid #000;
    border-radius: 40px;
    > .phone_content {
      padding: 0 13px;
      margin-bottom: 10px;
      > div {
        position: relative;
        margin-bottom: 10px;
      }
    }
    .phone_banner {
      min-height: 150px;
      text-align: center;
      background-color: #999;
    }
    .phone_application {
      width: 100%;
      min-height: 115px;
      // height: auto;
      .application_item {
        width: 25%;
        margin-bottom: 5px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }
    }
    .pushBanner {
      width: 100%;
      height: 120px;
    }
    .Selected {
      // min-height: 300px;
      .course_list {
        padding: 10px 0;
        img {
          width: 100px;
          height: 70px;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }
    .workPlace {
      padding: 10px 5px 20px;
      .course_list {
        padding: 10px 0;
        img {
          width: 100px;
          height: 70px;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(203, 209, 255, 0.4);
    z-index: 99;
    width: 100%;
    height: 100%;
    border: 2px dashed #001bff;
    display: flex;
    justify-content: flex-end;
    div {
      align-self: flex-end;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 5px;
      }
    }
  }
}
</style>

